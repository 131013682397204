import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import scopeCss from 'scope-css';
import Head from '../components/Head';
import Layout from '../components/Layout';
import { Router } from '../routes.mjs';
import throw404 from '../lib/throw404';

if (!process.browser) {
  // eslint-disable-next-line global-require, unicorn/prefer-module
  global.DOMParser = new (require('jsdom').JSDOM)().window.DOMParser;
}

export const handleClick = (event) => {
  const link = event.currentTarget;
  const href = link.getAttribute('href');
  const target = link.getAttribute('target');
  const url = new URL(href || '', window.location.href);
  const isInternalLink = url.origin === window.location.origin;
  const isOpenedInSameWindow = !target || target === '_self';
  const isLeftButtonClick = event.button === 0;
  const isModifierKeyPressed = event.altKey || event.ctrlKey
    || event.metaKey || event.shiftKey;

  if (
    isInternalLink
    && isOpenedInSameWindow
    && isLeftButtonClick
    && !isModifierKeyPressed
  ) {
    event.preventDefault();
    Router.pushRoute(`${url.pathname}${url.search}${url.hash}`);
  }
};

const removeNode = (node) => node && node.remove();
const removeNodes = (dom, selector) => [...dom.querySelectorAll(selector)]
  .forEach((node) => removeNode(node));
const getStyles = (dom) => scopeCss(
  [...dom.querySelectorAll('style:not([amp-boilerplate])')]
    .map((element) => element.innerHTML).join('\n'), '#landing',
)
  .replaceAll(/#landing\s+(body|html)/g, '#landing');

const removeTrailingSlash = (dom) => {
  dom.querySelectorAll(
    'a[href^="/"][href$="/"], a[href^="https://fashionunited.com/"][href$="/"]',
  ).forEach((element) => {
    element.setAttribute(
      'href',
      element.getAttribute('href').replace(/\/$/, ''),
    );
  });
};

const updateImagePaths = (dom, path) => {
  dom.querySelectorAll(
    'amp-img:not([src^="/"]):not([src*="://"])',
  ).forEach((element) => {
    element.setAttribute('src', `${path}/${element.getAttribute('src')}`);
  });
};

export const Landing = ({
  body,
  title,
  description,
  style,
  notFound,
  imageUrl,
  keywords,
  wordCount,
  hasEmbedder,
}) => {
  const reference = useRef(null);
  const { query } = useRouter();

  useEffect(() => {
    let links = [];

    if (reference.current) {
      links = reference.current.querySelectorAll('a');
      links.forEach((link) => {
        link.addEventListener('click', handleClick);
      });
    }

    return () => {
      links.forEach(
        (link) => {
          link.removeEventListener('click', handleClick);
        },
      );
    };
  }, [query]);

  useEffect(() => {
    if (hasEmbedder) {
      const script = document.createElement('script');
      script.async = true;
      script.src = '/embed.js';
      document.querySelector('#landing').append(script);
    }
  }, [body, hasEmbedder]);

  if (notFound) {
    return throw404();
  }

  return (
    <>
      <Head
        title={title}
        description={description}
        imageUrl={imageUrl}
        prelude={(
          <link rel="preload" as="script" href="https://cdn.ampproject.org/v0.js" />
        )}
      >
        <style
          // eslint-disable-next-line react/no-unknown-property
          amp-boilerplate=""
        >
          { // eslint-disable-next-line max-len
            'body{-webkit-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-moz-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-ms-animation:-amp-start 8s steps(1,end) 0s 1 normal both;animation:-amp-start 8s steps(1,end) 0s 1 normal both}@-webkit-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-moz-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-ms-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-o-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}'
          }
        </style>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: style }}
        />
        <meta itemProp="wordCount" content={wordCount} />
        <meta itemProp="keywords" content={keywords} />
        <script async src="https://cdn.ampproject.org/v0.js" />
        <script
          async
          // eslint-disable-next-line react/no-unknown-property
          custom-element="amp-ad"
          src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"
        />
        <script
          async
          // eslint-disable-next-line react/no-unknown-property
          custom-element="amp-selector"
          src="https://cdn.ampproject.org/v0/amp-selector-0.1.js"
        />
        <script
          async
          // eslint-disable-next-line react/no-unknown-property
          custom-element="amp-accordion"
          src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js"
        />
        <script
          async
          // eslint-disable-next-line react/no-unknown-property
          custom-element="amp-carousel"
          src="https://cdn.ampproject.org/v0/amp-carousel-0.1.js"
        />
        <script
          async
          // eslint-disable-next-line react/no-unknown-property
          custom-element="amp-youtube"
          src="https://cdn.ampproject.org/v0/amp-youtube-0.1.js"
        />
      </Head>

      <noscript>
        <style
          // eslint-disable-next-line react/no-unknown-property
          amp-boilerplate=""
        >
          { // eslint-disable-next-line max-len
            'body{-webkit-animation:none;-moz-animation:none;-ms-animation:none;animation:none}'
          }
        </style>
      </noscript>
      <div
        id="landing"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: body }}
        ref={reference}
      />
    </>
  );
};

Landing.getInitialProps = async ({ req, res, asPath }) => {
  const hostname = req ? req.get('host') : window.location.hostname;
  const path = asPath
    .replace(/^https?:\/\/[^/]+/, '')
    .replace(/\/?(\?.*)?$/, '');
  const url = `https://${hostname}${path}/?landing=true`;
  const response = await fetch(url);

  if (!res && response.type && response.type !== 'basic') {
    window.location.reload();

    return {};
  }

  if (response.url !== url) {
    if (res) {
      if (!res.finished) {
        res.writeHead(301, {
          Location: response.url,
        });
        res.end();
      }
    } else {
      const responseUrl = new URL(response.url);
      Router.pushRoute(responseUrl.pathname.replace(/\/$/, ''));
    }

    return {};
  }

  if (res && !res.headersSent) {
    res.setHeader('Surrogate-Key', 'landing pwa-ssr');
    res.setHeader('Cache-Tag', 'landing,pwa-ssr');
  }

  if (!response.ok) {
    return {
      notFound: true,
    };
  }

  const html = await response.text();

  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');

  const style = getStyles(dom);
  const hasEmbedder = Boolean(dom.querySelector('script[src="/embed.js"]'));
  removeTrailingSlash(dom);
  updateImagePaths(dom, path);
  removeNodes(dom, 'script');
  removeNodes(dom, 'style');

  return {
    title: dom.querySelector('title')?.textContent,
    description: dom
      .querySelector('meta[itemprop=description]')?.getAttribute('content'),
    imageUrl: dom
      .querySelector('meta[property="og:image"]')?.getAttribute('content'),
    keywords: dom
      .querySelector('meta[itemprop=keywords]')?.getAttribute('content'),
    wordCount: dom
      .querySelector('meta[itemprop=wordCount]')?.getAttribute('content'),
    style,
    body: dom.body.innerHTML,
    hasEmbedder,
  };
};

Landing.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  keywords: PropTypes.string,
  wordCount: PropTypes.string,
  style: PropTypes.string,
  notFound: PropTypes.bool,
  hasEmbedder: PropTypes.bool,
};

Landing.defaultProps = {
  body: null,
  title: null,
  description: null,
  imageUrl: null,
  keywords: null,
  wordCount: null,
  style: null,
  notFound: false,
  hasEmbedder: false,
};

Landing.getLayout = (page) => (
  <Layout layoutMaxWidth="100vw" layoutMarginBottom="0">
    {page}
  </Layout>
);

export default Landing;
